//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import componentMinxins from '../ComponentMinxins'
import OrgPicker from "@/page/admin/approval-process/component/common/OrgPicker";

export default {
  mixins: [componentMinxins],
  name: "RankPicker",
  components: {OrgPicker},
  props: {
    value:{
      type: Array,
      default: () => {
        return []
      }
    },
    placeholder: {
      type: String,
      default: '请选择职级'
    },
    multiple:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showOrgSelect: false
    }
  },
  methods: {
    selected(values){
      this.showOrgSelect = false
      this._value = values
    },
    delDept(i){
      this._value.splice(i, 1)
    }
  }
}
